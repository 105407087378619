import { useEffect, useState } from "react";
import axios from "axios";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container, Table } from "reactstrap";
import { ReactSortable } from "react-sortablejs";
import { Link } from "react-router-dom";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const SortGallery = () => {
  const [main, setMain] = useState([]);
  const accessToken = useSelector((state) => state.form.accessToken);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery`)
      .then((res) => {
        setMain(res.data);
      });
  }, []);

  function renderPortfolioTable() {
    return main.map((portfo, index) => {
      return (
        <tr className="my-list" key={index}>
          <td className="id">
            <div>
              <ion-icon name="menu-outline"></ion-icon>
            </div>
          </td>
          <td className="id">{portfo.id}</td>
          <td className="id">
            <img
              className="list-img"
              src={`${portfo.src}`}
            />
          </td>
          <td className="title">{portfo.fileName}</td>
          <div className="icons">
            <td
              className="icon"
              onClick={() => deleteImage(portfo.fileName)}
            >
              <div>
                <ion-icon name="trash-outline"></ion-icon>
              </div>
            </td>
          </div>
        </tr>
      );
    });
  }

  function deleteImage(fileName) {
    axios
      .delete(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/image/delete/image/gallery/${fileName}`
      )
      .then((res) => {
        setMain(res.data);
        window.location.reload();
      });
  }

  function sendData(index) {
    var data = main[index];
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/image/sort/gallery/images`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {});
  }

  function sortPortfolios() {
    main.map((item, index) => {
      item.position = index;
      sendData(index);
    });
  }

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <div className="break dash"></div>
        <Container className="animate-in">
          <div className="spacer mobile main"></div>
          <div className="d-flex justify-content-end">
            <Link to={`/admin/image/list/gallery`} className="editor-button left">
              <button className="send-button editor">ZPĚT</button>
            </Link>
            <Link
              to={`/admin/image/upload/gallery`}
              className="editor-button right"
            >
              <button className="send-button editor">PŘIDAT</button>
            </Link>
          </div>
          <Table
            responsive
            className="bg-light text-dark mt-3 border-radius p-1 rounded"
          >
            <tr className="my-list head">
              <td className="id"></td>
              <td className="id">Id</td>
              <td className="id">Fotka</td>
              <td className="title">Název</td>
              <div className="icons">
                <td className="icon"></td>
              </div>
            </tr>
            <ReactSortable
              group="groupName"
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={main}
              setList={setMain}
            >
              {renderPortfolioTable()}
            </ReactSortable>
          </Table>
          <button className="send-button" onClick={() => sortPortfolios()}>
            ULOŽIT ŘAZENÍ
          </button>
        </Container>
      </>
    );
  }
};

export default SortGallery;
