import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container, Table } from "reactstrap";
import { ReactSortable } from "react-sortablejs";
import { Link, useParams } from "react-router-dom";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const PortfolioList = () => {
  const [portfolio, setPortfolio] = useState([]);
  const { id } = useParams();
  const accessToken = useSelector((state) => state.form.accessToken);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/portfolio/admin/${id}`)
      .then((res) => {
        setPortfolio(res.data);
      });
  }, [id]);

  function changeMain(id) {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/image/change/main/image/portfolio/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setPortfolio(res.data);
      });
  }

  function renderPortfolioTable() {
    return portfolio.map((portfo, index) => {
      return (
        <tr className="my-list">
          <td className="id">
            <i class="bi bi-arrows-move"></i>
          </td>
          <td className="id">{portfo.id}</td>
          <td className="id">
            <img
              className="list-img"
              src={`/assets/images/portfolio/${id}/${portfo.fileName}`}
            />
          </td>
          <td className="title">{portfo.fileName}</td>
          <td className="title">
            <button className={`${portfo.isMain ? "green" : "orange"}`} onClick={() => changeMain(portfo.id)}>
              {portfo.isMain ? "Ano" : "Ne"}
            </button>
          </td>
          <div className="icons">
            <td className="icon">
              <i
                class="bi bi-trash3"
                onClick={() => deletePortfolioImage(portfo.fileName)}
              ></i>
            </td>
          </div>
        </tr>
      );
    });
  }

  function deletePortfolioImage(fileName) {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/image/delete/image/portfolio/${id}/${fileName}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setPortfolio(res.data);
      });
  }

  function sendData(index) {
    var data = portfolio[index];
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/image/sort/images/portfolio`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {});
  }

  function sortPortfolios() {
    portfolio.map((item, index) => {
      item.position = index;
      sendData(index);
    });
  }

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"></div>
          <div className="d-flex justify-content-end">
            <Link
              to={`/admin/edit/portfolio/${id}`}
              className="editor-button left"
            >
              <button className="send-button editor">ZPĚT</button>
            </Link>
            <Link
              to={`/admin/add/portfolio/images/${id}`}
              className="editor-button right"
            >
              <button className="send-button editor">PŘIDAT</button>
            </Link>
          </div>
          <Table
            responsive
            className="bg-light text-dark mt-3 border-radius p-1 rounded"
          >
            <tr className="my-list head">
              <td className="id"></td>
              <td className="id">Id</td>
              <td className="id">Fotka</td>
              <td className="title">Název</td>
              <td className="title">Je hlavní? (Náhledová)</td>
              <div className="icons">
                <td className="icon"></td>
              </div>
            </tr>
            <ReactSortable
              group="groupName"
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={portfolio}
              setList={setPortfolio}
            >
              {renderPortfolioTable()}
            </ReactSortable>
          </Table>
          <button className="send-button" onClick={() => sortPortfolios()}>
            ULOŽIT ŘAZENÍ
          </button>
        </Container>
      </>
    );
  }
};

export default PortfolioList;
