import React from "react";

const Loading = () => {
  return (
    <>
      <section className="align-items-center d-flex flex-column min-vh-100 justify-content-center align-items-center bg-white">
        <div class="snippet" data-title=".dot-typing">
          <div class="stage">
            <div class="dot-typing"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loading;