import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Unauthorized from "../../../shared/Unauthorized";

const getNameOfPage = (page) => {
  if (page === "about") {
    return "O mně";
  } else if (page === "pricing") {
    return "Ceník";
  } else if (page === "portfolio") {
    return "Portfolio";
  } else if (page === "gallery") {
    return "Galerie";
  } else if (page === "contact") {
    return "Kontakt";
  } else if (page === "blog") {
    return "Blog";
  }
};

function Editor(props) {
  const [theme, setTheme] = useState("snow");
  const { id, inputRef, placeholder } = props;
  const { ides, inputRefes, placeholderes } = props;
  const params = useParams();
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const accessToken = useSelector((state) => state.form.accessToken);
  const navigate = useNavigate();

  const saveText = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/portfolio/add`,
        {
          text: text,
          title: title,
          price: price,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then((response) => {
        navigate("/admin/add/portfolio/image/" + response.data);
      });
  };

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"></div>
          <div className="d-flex justify-content-evenly align-center">
            <h1 className="mb-3">{getNameOfPage(params.id)}</h1>
            <Link className="editor-button left" to="/admin/list/portfolio">
              <button className="send-button editor">ZPĚT</button>
            </Link>
          </div>
          <div className="login-form">
            <input
              name="title"
              type="text"
              placeholder="Název"
              className="control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <ReactQuill
            id={ides}
            ref={inputRefes}
            theme={theme}
            onChange={(value) => setPrice(value)}
            value={price}
            modules={{
              toolbar: {
                ...Editor.modules.toolbar,
                handlers: {},
              },
              ...Editor.modules,
            }}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={"Ceny a nejnutnější informace"}
            className="mt-3"
          />
          <ReactQuill
            id={id}
            ref={inputRef}
            theme={theme}
            className="mt-3"
            onChange={(value) => setText(value)}
            value={text}
            modules={{
              toolbar: {
                ...Editor.modules.toolbar,
                handlers: {},
              },
              ...Editor.modules,
            }}
            formats={Editor.formats}
            bounds={".app"}
            placeholder={"Text"}
          />
          <button
            className="send-button save mb-3"
            type="submit"
            onClick={() => saveText()}
          >
            PŘIDAT
          </button>
        </Container>
      </>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
    [{ align: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default Editor;
