import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { NavbarAdmin } from "../../shared/admin/NavbarAdmin";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";
import FileUploadHeaderEdit from "./Utils/FileUploadHeaderEdit";
import Unauthorized from "../../shared/Unauthorized";
import Loading from "../../shared/Loading";

const ImageEdit = () => {
  const accessToken = useSelector((state) => state.form.accessToken);
  const { type } = useParams();
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/header/${type}`)
      .then((res) => {
        setFileName(res.data);
        setLoading(false);
      });
  }, [type]);

  if (!accessToken) {
    <Unauthorized />;
  } else if (loading) {
    <Loading />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer"> </div>
          <Link className="editor-button right" to="/admin/dashboard">
            <button className="send-button editor">ZPĚT</button>
          </Link>
          <div className="image-editer">
            <div className="admin page-header animate-in">
              <img
                src={`/assets/images/${type}/${fileName}`}
                alt="edit"
                className="page-img-header"
              />
              <h1>
                {type === "portfolio"
                  ? "PORTFOLIO"
                  : type === "pricing"
                  ? "CENÍK"
                  : "BLOG"}
              </h1>
            </div>
            <div className="image-edit-section">
              <button
                className="send-button mb-5"
                onClick={() => setEdit(!edit)}
              >
                ZMĚNIT FOTKU
              </button>
              {edit ? (
                <>
                  <FileUploadHeaderEdit type={type} edit={"edit"} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default ImageEdit;
