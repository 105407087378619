import React from "react";
import { useParams } from "react-router-dom";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container } from "reactstrap";
import FileUploadBlogMain from "../Utils/FileUploadBlogMain";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const PortfolioUploadMainImage = () => {
  const { id } = useParams();
  const accessToken = useSelector((state) => state.form.accessToken);

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
  return (
    <>
      <NavbarAdmin />
      <Container className="animate-in">
        <div className="spacer main"> </div>
        <h1 className="mb-3">Nahraj hlavní fotku blogu</h1>
        <FileUploadBlogMain id={id} />
      </Container>
    </>
  );}
};

export default PortfolioUploadMainImage;
