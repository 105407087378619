import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import axios from "axios";
import FileUploadGalleryUpload from "../Utils/FileUploadGalleryUpload";
import { Link } from "react-router-dom";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

function GalleryList() {
  const [images, setImages] = useState([]);
  const [edit, setEdit] = useState(false);
  const accessToken = useSelector((state) => state.form.accessToken);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery`)
      .then((res) => {
        setImages(res.data);
      });
  }, []);

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"> </div>
          <Link className="editor-button left" to="/admin/dashboard">
            <button className="send-button editor">ZPĚT</button>
          </Link>
          <div className="content-page animate-in">
            <h1>GALERIE</h1>
            <div className="image-edit-section">
              <FileUploadGalleryUpload />
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default GalleryList;
