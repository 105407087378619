import React, { useState, useEffect } from "react";
import { Nav } from "./Nav";
import Logo from "../assets/images/logo.svg";
import { Cross as Hamburger } from "hamburger-react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const accessToken = useSelector((state) => state.form.accessToken);

  const changeNavbarColor = () => {
    if (window.scrollY >= 50) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  function blockit(boolHamb) {
    var cover = document.getElementById("cover");

    if (boolHamb) {
      cover.classList.add("covering");
    } else if (!boolHamb) {
      cover.classList.remove("covering");
    }
  }

  useEffect(() => {
    blockit(isOpen);
  }, [isOpen]);

  return (
    <>
      <div id="cover"></div>
      <header className={`${colorChange ? "active" : ""}`}>
        <a href="/">
          <img
            src={`${Logo}`}
            className={`active logo-nav`}
            style={{ animationDelay: "500ms" }}
            alt="logo"
          />
        </a>
        <Nav active={isOpen} />
        <div className="navmenu-flex-basket">
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            color="#fd81a8"
            rounded
          />
        </div>
      </header>
      {accessToken ? (
        <>
          <Link to="/admin/dashboard">
            <button className="send-button admin">ADMIN</button>
          </Link>
        </>
      ) : <></>}
    </>
  );
};
