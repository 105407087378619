import React, { Fragment, useState } from "react";
import Progress from "./Progress";
import axios from "axios";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Compressor from "compressorjs";
import removeAccents from "remove-accents";
import squash from "just-squash";

const FileUploadEdit = ({ id }) => {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const accessToken = useSelector((state) => state.form.accessToken);
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const navigate = useNavigate();

  const onChange = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.4,
      maxWidth: 3840,
      maxHeight: 2160,
      resize: "cover",
      success: (compressedResult) => {
        setFile(compressedResult);
      },
    });
    setFilename(squash(removeAccents(e.target.files[0].name)));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/image/upload/image/blog/main/${id}/${filename}`,
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-url-formencoded",
            Authorization: "Bearer " + accessToken,
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        }
      );

      // Clear percentage
      setTimeout(() => setUploadPercentage(0), 10000);

      const { fileName, filePath } = res.data;

      setUploadedFile({ fileName, filePath });

      setMessage("File Uploaded");
      navigate("/admin/list/blog");
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
      }
      setUploadPercentage(0);
    }
  };

  return (
    <Container>
      <Fragment>
        <form onSubmit={onSubmit}>
          <div className="custom-file mb-4">
            <input
              type="file"
              accept="image/*"
              className="custom-file-input"
              id="customFile"
              onChange={onChange}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {filename}
            </label>
          </div>

          <Progress percentage={uploadPercentage} />

          <input type="submit" value="NAHRÁT" className={`send-button mt-4`} />
        </form>
        {uploadedFile ? (
          <div className="row mt-5">
            <div className="col-md-6 m-auto">
              <h3 className="text-center">{uploadedFile.fileName}</h3>
              <img
                style={{ width: "100%" }}
                src={uploadedFile.filePath}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </Fragment>
    </Container>
  );
};

export default FileUploadEdit;
