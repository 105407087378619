import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment-with-locales-es6";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import WIPState from "../shared/WIPState";

const BlogDesc = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [shortcutDesc, setShortcutDesc] = useState("");
  const [index, setIndex] = useState(-1);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/blog/description/${id}`)
      .then((res) => {
        setPortfolio(res.data);
        setShortcutDesc(
          res.data.text.split(" ").slice(0, 50).join(" ") + "..."
        );
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/blog/link/${id}`)
      .then((res) => {
        setImage(res.data);
        setLoading(false);
      });
  }, [id]);

  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title={`${portfolio.title} | Veronika Vavrincová`}
          description={`${shortcutDesc}`}
          name="Veronika Vavrincová"
          type="Blog"
        />
        <Navbar />
        <Container>
          <div className="spacer mobile portfolio"> </div>
          <div className="content-page blog animate-in">
            <Row>
              <Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="types-desc">
                  <h1>{portfolio.title}</h1>
                  <p className="text-muted">
                    {moment(`${portfolio.dateWritten}`)
                      .locale("cs")
                      .format("L")}{" "}
                    | {portfolio.lengthRead} min. čtení | {portfolio.category}
                  </p>
                  <UnsafeComponent html={portfolio.text} />
                </div>
              </Col>
            </Row>
            <div className="image-gallery-types">
              <PhotoAlbum
                layout="rows"
                photos={image}
                onClick={({ index }) => setIndex(index)}
                renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                  <div style={{ position: "relative", ...wrapperStyle }}>
                    {renderDefaultPhoto({ wrapped: true })}
                    {photo.title && (
                      <div
                        style={{
                          position: "absolute",
                          overflow: "hidden",
                          backgroundColor: "rgba(255 255 255 / .6)",
                          inset: "auto 0 0 0",
                          padding: 8,
                        }}
                      >
                        {photo.fileName}
                      </div>
                    )}
                  </div>
                )}
              />
              <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={image}
                plugins={[Zoom, Counter]}
                counter={{ container: { style: { top: "unset", bottom: 0 } } }}
              />
            </div>
          </div>
        </Container>
      </>
    );
  }
};

export default BlogDesc;
