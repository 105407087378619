import React from "react";
import { useParams } from "react-router-dom";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container } from "reactstrap";
import FileUploadPortfolioMain from "../Utils/FileUploadPortfolioMain";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const PortfolioUploadMainImage = () => {
  const { id } = useParams();
  const accessToken = useSelector((state) => state.form.accessToken);

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer main"> </div>
          <h1 className="mb-3">Nahraj hlavní fotku portfolia</h1>
          <FileUploadPortfolioMain id={id} />
        </Container>
      </>
    );
  }
};

export default PortfolioUploadMainImage;
