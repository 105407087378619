import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CLEAR_ACCESS_TOKEN } from "../../reducer";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/account/logout`)
      .then(() => {
        dispatch({
          type: CLEAR_ACCESS_TOKEN,
        });
      })
      .then(() => {
        navigate("/");
      });
  }, [dispatch, navigate]);
};

export default Logout;
