import React from "react";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../shared/admin/NavbarAdmin";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Unauthorized from "../../shared/Unauthorized";
import axios from "axios";

const Dashboard = () => {
  const accessToken = useSelector((state) => state.form.accessToken);

  const updateFoto = () => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery/update/size`)
      .then(() => {
        console.log("hotovo");
      });
  };

  if (!accessToken) {
    return <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <div className="spacer mobile main"> </div>
        <Container>
          <div className="admin-dashboard animate-in mb-3">
            <div class="admin-card">
              <h2>Home</h2>
              <Link to="/admin/image/list/main">
                <button class="send-button" type="button">
                  OBRÁZKY
                </button>
              </Link>
              <Link to="/admin/text-edit/main">
                <button class="send-button" type="button">
                  TEXT
                </button>
              </Link>
            </div>
            <div class="admin-card">
              <h2>O mně</h2>
              <Link to="/admin/image/upload/about">
                <button class="send-button" type="button">
                  OBRÁZEK
                </button>
              </Link>
              <Link to="/admin/text-edit/about">
                <button class="send-button" type="button">
                  TEXT
                </button>
              </Link>
            </div>
            <div class="admin-card">
              <h2>Ceník</h2>
              <Link to="/admin/header/pricing/image">
                <button class="send-button" type="button">
                  FOTKA V ZÁHLAVÍ
                </button>
              </Link>
              <Link to="/admin/list/portfolio">
                <button class="send-button" type="button">
                  KATEGORIE
                </button>
              </Link>
            </div>
            <div class="admin-card">
              <h2>Portfolio</h2>
              <Link to="/admin/header/portfolio/image">
                <button class="send-button" type="button">
                  FOTKA V ZÁHLAVÍ
                </button>
              </Link>
              <Link to="/admin/list/portfolio">
                <button class="send-button" type="button">
                  KATEGORIE
                </button>
              </Link>
            </div>
            <div class="admin-card">
              <h2>Galerie</h2>
              <Link to="/admin/image/list/gallery">
                <button class="send-button" type="button">
                  ZOBRAZIT
                </button>
              </Link>
            </div>
            <div class="admin-card">
              <h2>Blog</h2>
              <Link to="/admin/header/blog/image">
                <button class="send-button" type="button">
                  FOTKA V ZÁHLAVÍ
                </button>
              </Link>
              <Link to="/admin/list/blog">
                <button class="send-button" type="button">
                  KATEGORIE
                </button>
              </Link>
            </div>
            <div class="admin-card">
              <h2>Update fotek (výška,šířka)</h2>
              <button
                class="send-button"
                type="button"
                onClick={() => updateFoto()}
              >
                UPDATE
              </button>
            </div>
          </div>
          {/*<button
            class="send-button"
            type="button"
            onCLick={() => updatePhotos()}
          >
            UPDATE FOTEK
    </button>*/}
        </Container>
      </>
    );
  }
};

export default Dashboard;
