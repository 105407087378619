import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Label } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Povinné"),
  lastName: Yup.string().required("Povinné"),
  email: Yup.string().email("Nesprávný email!").required("Povinné"),
  tel: Yup.string()
    .matches(phoneRegExp, "Telefonní číslo není validní!")
    .required("Povinné"),
});

const SendForm = ({ text }) => {
  const navigate = useNavigate();

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          tel: "",
          text: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          axios
            .post(
              `${process.env.REACT_APP_BACKEND_URL}/text/send/email`,
              {
                name: values.firstName + " " + values.lastName,
                email: values.email,
                telNumber: values.tel,
                text: values.text,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              navigate("/dekuji");
            })
            .catch((err) => {
            });
        }}
      >
        {({ errors, touched }) => (
          <div className="kontakt-form">
            <Form className="kontakt">
              <Label className="label-input">Jméno</Label>
              <Field name="firstName" placeholder="Jan" />
              {errors.firstName && touched.firstName ? (
                <div className="error-input">{errors.firstName}</div>
              ) : null}
              <Label className="label-input">Příjmení</Label>
              <Field name="lastName" placeholder="Novák" />
              {errors.lastName && touched.lastName ? (
                <div className="error-input">{errors.lastName}</div>
              ) : null}
              <Label className="label-input">Telefonní číslo</Label>
              <Field name="tel" type="tel" placeholder="727486239" />
              {errors.tel && touched.tel ? (
                <div className="error-input">{errors.tel}</div>
              ) : null}
              <Label className="label-input">E-mail</Label>

              <Field
                name="email"
                type="email"
                placeholder="jan.novak@email.cz"
              />
              {errors.email && touched.email ? (
                <div className="error-input">{errors.email}</div>
              ) : null}

              <Label className="label-input">Zpráva</Label>
              <Field
                as="textarea"
                name="text"
                type="text"
                placeholder="Vaše zpráva/objednávka..."
              />
              {errors.text && touched.text ? (
                <div className="error-input">{errors.text}</div>
              ) : null}

              <button className="send-button" type="submit">
                {text}
              </button>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default SendForm;
