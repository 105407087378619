export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const CLEAR_ACCESS_TOKEN = "CLEAR_ACCESS_TOKEN";

const copyMultidimensionalArray = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

const userId = {
  accessToken: null,
  role: null,
  userId: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = userId, action = {}) {
  switch (action.type) {
    case SET_ACCESS_TOKEN: {
      let temp = copyMultidimensionalArray(state);
      temp.accessToken = action.payload;
      var tempParse = parseJwt(action.payload);
      temp.role = tempParse.family_name;
      temp.userId = tempParse.given_name;
      return temp;
    }
    case CLEAR_ACCESS_TOKEN: {
      state.accessToken = null;
      state.role = null;
      state.userId = "";
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
