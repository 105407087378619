import React from "react";
import { Link, useParams } from "react-router-dom";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import { Container } from "reactstrap";
import FileUploadPortfolio from "../Utils/FileUploadPortfolio";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";

const PortfolioUploadMainImage = () => {
  const { id } = useParams();
  const accessToken = useSelector((state) => state.form.accessToken);

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
  return (
    <>
      <NavbarAdmin />
      <Container className="animate-in">
        <div className="spacer main"> </div>
        <Link to={`/admin/add/portfolio/images/list/${id}`}>
          <button className="send-button editor">ZPĚT</button>
        </Link>
        <h1 className="mb-3">Nahraj fotky do portfolia</h1>
        <FileUploadPortfolio id={id} />
      </Container>
    </>
  );}
};

export default PortfolioUploadMainImage;
