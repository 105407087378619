import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { Navbar } from "../shared/Navbar";
import axios from "axios";
import Loading from "../shared/Loading";
import SEO from "../shared/SEO";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import WIPState from "../shared/WIPState";

const Gallery = () => {
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState([]);
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery`)
      .then((res) => {
        setImage(res.data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title="Galerie | Veronika Vavrincová"
          description="Galerie Veronika Vavrincová. Náhledy fotek z focení mých klientů."
          name="Veronika Vavrincová"
          type="Gelerie"
        />
        <Navbar />
        <Container>
          <div className="spacer mobile main"> </div>
          <div className="content-page animate-in">
            <h1>GALERIE</h1>
          </div>
          <div className="image-gallery-types">
          <PhotoAlbum
              layout="rows"
              photos={image}
              onClick={({ index }) => setIndex(index)}
              renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                <div style={{ position: "relative", ...wrapperStyle }}>
                  {renderDefaultPhoto({ wrapped: true })}
                  {photo.title && (
                    <div
                      style={{
                        position: "absolute",
                        overflow: "hidden",
                        backgroundColor: "rgba(255 255 255 / .6)",
                        inset: "auto 0 0 0",
                        padding: 8,
                      }}
                    >
                      {photo.fileName}
                    </div>
                  )}
                </div>
              )}
            />
            <Lightbox
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              slides={image}
              plugins={[Zoom, Counter]}
              counter={{ container: { style: { top: "unset", bottom: 0 } } }}
            />
          </div>
        </Container>
      </>
    );
  }
};

export default Gallery;
