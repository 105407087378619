import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { NavbarAdmin } from "../../../shared/admin/NavbarAdmin";
import axios from "axios";
import { Link } from "react-router-dom";
import Unauthorized from "../../../shared/Unauthorized";
import { useSelector } from "react-redux";
import { AspectRatio } from "react-aspect-ratio";
import imageSize from "@coderosh/image-size";
import PhotoAlbum from "react-photo-album";

function GalleryList() {
  const [image, setImage] = useState([]);
  const [index, setIndex] = useState(-1);
  const accessToken = useSelector((state) => state.form.accessToken);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/gallery`)
    .then((res) => {
        setImage(res.data);
      });
  }, []);

  function deleteImage(fileName) {
    console.log(fileName);
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/image/delete/image/gallery/${fileName}`
      )
      .then((res) => {
        setImage(res.data);
        window.location.reload();
      });
  }

  if (!accessToken) {
    <Unauthorized />;
  } else if (accessToken) {
    return (
      <>
        <NavbarAdmin />
        <Container className="animate-in">
          <div className="spacer mobile main"> </div>
          <div className="d-flex justify-content-evenly align-center">
            <Link className="editor-button left" to="/admin/dashboard">
              <button className="send-button editor">ZPĚT</button>
            </Link>
            <Link
              className="editor-button"
              to="/admin/image/sort/gallery"
            >
              <button className="send-button editor">SEŘADIT FOTKY</button>
            </Link>
            <Link
              className="editor-button right"
              to="/admin/image/upload/gallery"
            >
              <button className="send-button editor">PŘIDAT FOTKY</button>
            </Link>
          </div>
          <div className="content-page animate-in">
            <h1>GALERIE</h1>
          </div>
          <div className="image-gallery-types">
          <PhotoAlbum
              layout="rows"
              photos={image}
              onClick={({ index }) => setIndex(index)}
              renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                <div style={{ position: "relative", ...wrapperStyle }}>
                  {renderDefaultPhoto({ wrapped: true })}
                  {photo.title && (
                    <div
                      style={{
                        position: "absolute",
                        overflow: "hidden",
                        backgroundColor: "rgba(255 255 255 / .6)",
                        inset: "auto 0 0 0",
                        padding: 8,
                      }}
                    >
                      {photo.fileName}
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </Container>
      </>
    );
  }
}

export default GalleryList;
