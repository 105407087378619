import axios from "axios";
import React, { useEffect, useState } from "react";
import Loading from "../shared/Loading";
import { Navbar } from "../shared/Navbar";
import SEO from "../shared/SEO";
import { Container, UncontrolledCarousel } from "reactstrap";
import WIPState from "../shared/WIPState";

const Home = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/image/images/main/preview`)
      .then((res) => {
        setImages(res.data);
        setLoading(false);
      });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/main/text`)
      .then((response) => {
        setText(response.data);
      });
  }, []);

  const [pageState, setPageState] = useState();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/text/pageState`)
      .then((res) => {
        setPageState(res.data);
      });
  }, []);

  var array = [];
  renderImages();
  function renderImages() {
    var index = 0;
    return images.map(
      (item) => (
        array.push({
          src: item,
          key: index,
          alt: "Veronika Vavrincová",
          caption: "Vítejte na mém webu",
        }),
        index++
      )
    );
  }

  if (loading) {
    return <Loading />;
  } else if (pageState) {
    return (
      <>
        <WIPState />
      </>
    );
  } else {
    return (
      <>
        <SEO
          title="Veronika Vavrincová | Fotografka, rodinné foto"
          description="Veronika Vavrincová | Fotografka, rodinné foto"
          name="Veronika Vavrincová"
          type="Home"
        />
        <Navbar />
        <div>
          <style>
            {`.custom-tag {
              width: 100%;
              height: 100vh;
              object-fit: cover;
            }`}
          </style>
          <div className="spacer mobile main absolute"></div>
          <Container>
            <div className="text-invite animate-in">{text}</div>
          </Container>
          <UncontrolledCarousel items={array} />
        </div>
      </>
    );
  }
};

export default Home;
