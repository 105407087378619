import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <div className="login-card animate-in">
        <h2>Nejste přihlášeni!</h2>
        <h3>Nemáte dostatečné práva</h3>
        <Link to="/admin">
          <button className="send-button">PŘIHLÁSIT SE</button>
        </Link>
      </div>
    </>
  );
};

export default Login;
