import { MyBubblyLink } from "../MyBubblyLink";

export const NavAdmin = ({active}) => (
  <>
    <nav className="desktop" style={{ animationDelay: "500ms" }}>
      <MyBubblyLink to="/" text="HOME/WEB" />
      <MyBubblyLink to="/admin/dashboard" text="DASHBOARD" />
      <MyBubblyLink to="/admin/website-state" text="STAV STRÁNKY" />
      <MyBubblyLink to="/admin/change/password" text="ZMĚNA HESLA" />
      <MyBubblyLink to="/admin/logout" text="ODHLÁSIT SE" />
    </nav>
    <nav className={`sidebar ${active  ? "active" : ""} mobile`}>
      <ul class={`sidebar-list`}>
      <MyBubblyLink to="/" text="HOME/WEB" />
      <MyBubblyLink to="/admin/dashboard" text="DASHBOARD" />
      <MyBubblyLink to="/admin/website-state" text="STAV STRÁNKY" />
      <MyBubblyLink to="/admin/change/password" text="ZMĚNA HESLA" />
      <MyBubblyLink to="/admin/logout" text="ODHLÁSIT SE" />
      </ul>
    </nav>
  </>
);
